import pageTitle from "../../images/page-title.png";
import icon from "../../images/search.png";
import buttonArrow from "../../images/button-arrow-white.png";

export default (theme) => ({
  pageTitle: {
    position: "relative",
    height: 140,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      height: 284,
    },
    "& h1": {
      fontSize: 52,
      lineHeight: 1.23,
      color: theme.palette.primary.contrastText,
      marginBottom: 0,
      position: "relative",
      fontWeight: 800,
      letterSpacing: "-0.008em",
    },
    "&::before": {
      display: "block",
      width: "100vw",
      position: "absolute",
      zIndex: 0,
      content: '""',
      top: 0,
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
      backgroundImage: "url(" + pageTitle + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
  },
  results: {
    paddingTop: 40,
    paddingBottom: 40,
    marginBottom: 32,
    position: "relative",
    [theme.breakpoints.up("md")]: {
      paddingTop: 80,
      paddingBottom: 120,
      marginBottom: 120,
    },
    "& h4": {
      marginBottom: 32,
      [theme.breakpoints.up("md")]: {
        marginBottom: 80,
      },
    },
    "&::before": {
      content: '""',
      backgroundColor: theme.palette.secondary.main + "0A",
      position: "absolute",
      bottom: 0,
      top: 0,
      display: "block",
      width: "100vw",
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: -1,
    },
  },
  stockist: {
    backgroundColor: theme.palette.primary.main + "14",
    textAlign: "center",
    padding: "40px 32px 32px",
    display: "block",
    textDecoration: "none",
    color: theme.palette.primary.main,
    transition: "opacity .3s ease-in-out",
    "&:hover, &:focus": {
      opacity: 0.85,
    },
    "& img": {
      marginBottom: 32,
    },
    "& span": {
      display: "block",
      fontSize: 18,
      fontWeight: "bold",
      letterSpacing: "0.008em",
    },
  },
  imageLinkRow: {
    marginBottom: 36,
    [theme.breakpoints.up("md")]: {
      paddingBottom: 140,
    },
    "& a.image-link": {
      "&:after": {
        backgroundImage: "url(" + buttonArrow + ")",
      },
    },
  },
});
