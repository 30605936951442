import React, {Component} from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import QuoteBuilder from '../components/Stockist/StockistListPage';

class Page extends Component {
  shouldRenderBackButton() {
    if (!this.props.location || !this.props.location.state) return false;

    return this.props.location.state.product;
  }

  render() {
    return (
      <Layout
        meta={{
          ...this.props.data.wpPage.Meta,
          canonical: '/online-stockist/'
        }}
        path={'a'}
        cta={'a'}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <QuoteBuilder stockists={this.props.data.allWpStockist} renderReturnButton={this.shouldRenderBackButton()} />
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allWpStockist(sort: {fields: title}, filter: {stockists: { online: {eq: true}}}) {
      edges {
        node {
          id
          title
          stockists {
            logo {
              id
              sourceUrl
            }
            online
            url
          }
        }
      }
    }
    wpPage(uri: {eq: "/"}) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export default Page
